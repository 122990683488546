













import { Component, VModel, Vue } from 'vue-property-decorator'

@Component({})
export default class Switcher extends Vue {
  @VModel({ type: [Boolean, String] }) checked!: boolean|string
}
